import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import LogoImg from '../../public/i-g-final.png'

import i18n from "i18next";
import {
  FooterSection,
  // Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  // Large,
  Chat,
  // Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

// interface SocialLinkProps {
//   href: string;
//   src: string;
// }

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  // const SocialLink = ({ href, src }: SocialLinkProps) => {
  //   return (
  //     <a
  //       href={href}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       key={src}
  //       aria-label={src}
  //     >
  //       <SvgIcon src={src} width="25px" height="25px" />
  //     </a>
  //   );
  // };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Language>{t("Contacts")}</Language>
              {/*<Large to="/">{t("Tell us everything")}</Large>*/}
              <Para>
                <b>{t("Phone")}</b>: +447379786219
              </Para>
              <Para>
                <b>{t("E-mail")}</b>: contact@intersoftglb.com
              </Para>
              <a href="mailto:contact@intersoftglb.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>Kaya W.F.G. (Jombi) Mensing 24 Unit A, Curaçao</Para>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                      src="united-states.svg"
                      aria-label="homepage"
                      width="30px"
                      height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                {/*<SvgIcon*/}
                {/*    src="logo.svg"*/}
                {/*    aria-label="homepage"*/}
                {/*    width="101px"*/}
                {/*    height="64px"*/}
                {/*/>*/}
                <img src={LogoImg} alt="logo" style={{
                  height: "64px",
                }}/>
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              {/*<SocialLink*/}
              {/*    href="https://github.com/Adrinlol/create-react-app-adrinlol"*/}
              {/*  src="github.svg"*/}
              {/*/>*/}
              {/*<SocialLink*/}
              {/*  href="https://twitter.com/Adrinlolx"*/}
              {/*  src="twitter.svg"*/}
              {/*/>*/}
              {/*<SocialLink*/}
              {/*  href="https://www.linkedin.com/in/lasha-kakabadze/"*/}
              {/*  src="linkedin.svg"*/}
              {/*/>*/}
              {/*<SocialLink*/}
              {/*  href="https://medium.com/@lashakakabadze/"*/}
              {/*  src="medium.svg"*/}
              {/*/>*/}
              {/*<a*/}
              {/*  href="https://ko-fi.com/Y8Y7H8BNJ"*/}
              {/*  target="_blank"*/}
              {/*  rel="noopener noreferrer"*/}
              {/*>*/}
              {/*  <img*/}
              {/*    height="36"*/}
              {/*    style={{ border: 0, height: 36 }}*/}
              {/*    src="https://storage.ko-fi.com/cdn/kofi3.png?v=3"*/}
              {/*    alt="Buy Me a Coffee at ko-fi.com"*/}
              {/*  />*/}
              {/*</a>*/}
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
